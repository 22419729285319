// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import SvgIconStyle from '../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  kanban: getIcon('ic_kanban'),
  calllog: getIcon('ic_call_log'),
  dashboard: getIcon('ic_dashboard'),
  reporting: getIcon('ic_reporting'),
  roles: getIcon('ic_roles'),
  routing: getIcon('ic_routing'),
  settings: getIcon('ic_settings')
};

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    items: [
      {
        title: 'Call log',
        path: PATH_DASHBOARD.calllog,
        icon: ICONS.calllog
      },

      {
        title: 'Reporting',
        path: PATH_DASHBOARD.reporting.root,
        icon: ICONS.reporting,
        children: [
          { title: 'Dashboard', path: PATH_DASHBOARD.reporting.dashboard },
          { title: 'Group reports', path: PATH_DASHBOARD.reporting.rolereports },
          { title: 'People reports', path: PATH_DASHBOARD.reporting.userreports }
        ]
      },
      {
        title: 'People',
        path: PATH_DASHBOARD.users,
        icon: ICONS.dashboard
      },
      {
        title: 'Groups',
        path: PATH_DASHBOARD.roles.root,
        icon: ICONS.roles
      },
      {
        title: 'Rules',
        path: PATH_DASHBOARD.routing.root,
        icon: ICONS.routing
      },
      {
        title: 'Settings',
        path: PATH_DASHBOARD.settings.root,
        icon: ICONS.settings,
        children: [
          { title: 'Account', path: PATH_DASHBOARD.settings.account },
          { title: 'Numbers', path: PATH_DASHBOARD.settings.numbers },
          { title: 'Integrations', path: PATH_DASHBOARD.settings.integrations },
          { title: 'Billing', path: PATH_DASHBOARD.settings.billing }
        ]
      }
    ]
  }
];

export default sidebarConfig;
