import React from 'react';

export function LoginBackground() {
  return (
    <img
      src="/static/brand/login_background.svg"
      alt=""
      {...{
        ...{
          style: {
            position: 'absolute',
            top: 0,
            left: 0,
            minHeight: '100vh',
            objectFit: 'cover',
            zIndex: -99
          }
        }
      }}
    />
  );
}
