// material
import { Grid, Button } from '@mui/material';
// import firebase from 'firebase';
// import firebase from 'firebase/compat/app';
// import { getAuth } from 'firebase/auth';
import useAuth from '../../hooks/useAuth';

// const config = {
//   apiKey: 'AIzaSyC1jM-lh8Xof-RO2SnRoEBwh6qfcUTJf1g',
//   authDomain: 'talk-like-humans.firebaseapp.com',
//   databaseURL: 'https://talk-like-humans.firebaseio.com',
//   projectId: 'talk-like-humans',
//   storageBucket: 'talk-like-humans.appspot.com',
//   messagingSenderId: '321618762946',
//   appId: '1:321618762946:ios:707df0e2e432b3e7'
// };
// let app = firebase.initializeApp(config);
// const auth = getAuth(app);
// hooks
// import useAuth from '../../hooks/useAuth'
// import { resetCradleAuth } from '../../redux/slices/cradleAuth'

// ----------------------------------------------------------------------

export default function AuthWithSocial() {
  const { login } = useAuth();

  const style = {
    color: '#646464',
    padding: '0 30px',
    fontSize: '12px'
  };

  return (
    <>
      <Grid item xs={6}>
        <Button
          fullWidth
          style={style}
          size="large"
          color="inherit"
          variant="outlined"
          onClick={() => {
            login('google');
            // const auth = firebase.auth();
            // const provider = new firebase.auth.GoogleAuthProvider();
            // auth
            //   .signInWithPopup(auth, provider)
            //   .then((result) => {
            //     const credential = provider.credentialFromResult(result);
            //     const token = credential.accessToken;
            //   })
            //   .catch((error) => {
            //     const errorCode = error.code;
            //     const errorMessage = error.message;
            //   });
          }}
        >
          <img src="/static/icons/ic_google.svg" alt="Google Icon" width={24} height={24} />
          &nbsp;&nbsp;Log in with Google Workspace
        </Button>
      </Grid>
      <Grid item xs={6}>
        <Button
          fullWidth
          style={style}
          size="large"
          color="inherit"
          variant="outlined"
          onClick={async () => {
            login('microsoft');
          }}
        >
          <img src="/static/icons/ic_microsoft.svg" alt="Microsoft Icon" width={24} height={24} />
          &nbsp;&nbsp;Log in with Microsoft 365 Business
        </Button>
      </Grid>
    </>
  );
}
