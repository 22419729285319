import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Container, Typography, Link, Grid } from '@mui/material';
// routes
import { PATH_AUTH } from '../../routes/paths';
// components
import Page from '../../components/Page';
import { LoginBackground } from './LoginBackground';
import LoginWithProvider from '../../components/authentication/LoginWithProvider';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 620,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function Login() {
  return (
    <RootStyle title="Login">
      <LoginBackground />
      <Container maxWidth="md">
        <ContentStyle>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography align="center" variant="h3" gutterBottom>
                Log in to Cradle Admin
              </Typography>
            </Grid>
            <LoginWithProvider />
            <Grid item xs={12}>
              <Typography variant="body2" align="center">
                If you don’t have an account&nbsp;
                <Link variant="subtitle2" component={RouterLink} to={PATH_AUTH.signup}>
                  you can get started here
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
