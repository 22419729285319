import moment from 'moment-timezone';

export const defaultFilter = {
  dateRange: { title: 'Past 7 days', value: 'past7days' },
  from: moment().subtract(7, 'days').startOf('day').toISOString(),
  until: moment().toISOString(),
  page: 1,
  pageSize: 25
};

export function resolveFromAndUntilFilter(range) {
  let fromMoment = moment();
  let untilMoment = moment();

  if (!range) {
    range = defaultFilter.dateRange.value;
  }

  switch (range) {
    case 'today':
      fromMoment = moment().startOf('day');
      break;
    case 'yesterday':
      fromMoment = moment().subtract(1, 'days').startOf('day');
      untilMoment = moment().subtract(1, 'days').endOf('day');
      break;
    case 'past7days':
      fromMoment = moment().subtract(7, 'days').startOf('day');
      break;
    case 'thisweek':
      fromMoment = moment().startOf('isoWeek');
      break;
    case 'lastweek':
      fromMoment = moment().subtract(1, 'weeks').startOf('isoWeek');
      untilMoment = moment().subtract(1, 'weeks').endOf('isoWeek');
      break;
    case 'past4weeks':
      fromMoment = moment().subtract(4, 'weeks').startOf('isoWeek');
      break;
    case 'thismonth':
      fromMoment = moment().startOf('month');
      break;
    case 'lastmonth':
      fromMoment = moment().subtract(1, 'months').startOf('month');
      untilMoment = moment().subtract(1, 'months').endOf('month');
      break;
    default:
      // default to last 7 days in case of something unknown
      fromMoment = moment().subtract(7, 'days').startOf('day');
      break;
  }

  return [fromMoment.toISOString(), untilMoment.toISOString()];
}
