// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}
const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '';
const ROOTS_ERROR = '/error';
export const SETUP_STEPPER = '/setup-step';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  link: path(ROOTS_AUTH, '/link'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  signup: path(ROOTS_AUTH, '/signup'),
  signupXero: path(ROOTS_AUTH, '/signup/xero'),
  signupForm: path(ROOTS_AUTH, '/signup/form'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify')
};

export const PATH_ERROR = {
  root: ROOTS_ERROR,
  nonuser: path(ROOTS_ERROR, '/non-user'),
  nonadmin: path(ROOTS_ERROR, '/non-admin'),
  domainexisted: path(ROOTS_ERROR, '/domain-existed'),
  domaingeneric: path(ROOTS_ERROR, '/domain-generic'),
  carddeclined: path(ROOTS_ERROR, '/card-declined'),
  firebaseerror: path(ROOTS_ERROR, '/clean-firebase-user-failed'),
  linkfailure: path(ROOTS_ERROR, '/link-failure'),
  linkrequired: path(ROOTS_ERROR, '/link-required')
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components'
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  calllog: path(ROOTS_DASHBOARD, '/calllog'),
  reporting: {
    root: path(ROOTS_DASHBOARD, '/reporting'),
    dashboard: path(ROOTS_DASHBOARD, '/reporting/dashboard'),
    rolereports: path(ROOTS_DASHBOARD, '/reporting/groupreports'),
    rolereportdetail: path(ROOTS_DASHBOARD, '/reporting/groupreports/:roleId'),
    userreports: path(ROOTS_DASHBOARD, '/reporting/peoplereports'),
    userreportdetail: path(ROOTS_DASHBOARD, '/reporting/peoplereports/:userId')
  },
  users: path(ROOTS_DASHBOARD, '/people'),
  user: {
    root: path(ROOTS_DASHBOARD, '/people'),
    profile: path(ROOTS_DASHBOARD, '/people/profile/:userId'),
    cards: path(ROOTS_DASHBOARD, '/people/cards'),
    list: path(ROOTS_DASHBOARD, '/people/list'),
    newUser: path(ROOTS_DASHBOARD, '/people/new'),
    editById: path(ROOTS_DASHBOARD, '/people/ada-lindgren/edit'),
    account: path(ROOTS_DASHBOARD, '/people/account')
  },
  roles: {
    root: path(ROOTS_DASHBOARD, '/groups'),
    roleslist: path(ROOTS_DASHBOARD, '/groups/groupslist'),
    createnewrole: path(ROOTS_DASHBOARD, '/groups/createnewgroup'),
    profile: path(ROOTS_DASHBOARD, '/groups/profile/:roleId')
  },
  routing: {
    root: path(ROOTS_DASHBOARD, '/rules'),
    routeslist: path(ROOTS_DASHBOARD, '/rules/ruleslist'),
    createnewroute: path(ROOTS_DASHBOARD, '/rules/createnewrule'),
    profile: path(ROOTS_DASHBOARD, '/rules/profile/:routingId')
  },
  settings: {
    root: path(ROOTS_DASHBOARD, '/settings'),
    account: path(ROOTS_DASHBOARD, '/settings/account'),
    numbers: path(ROOTS_DASHBOARD, '/settings/numbers'),
    createnewnumber: path(ROOTS_DASHBOARD, '/settings/createnewnumber'),
    integrations: path(ROOTS_DASHBOARD, '/settings/integrations'),
    billing: path(ROOTS_DASHBOARD, '/settings/billing')
  }
};
