import { createSlice } from '@reduxjs/toolkit';
// utils
import { getAddressesApi } from '../../utils/CradleClient';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  addressList: []
};

const slice = createSlice({
  name: 'address',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET Address LIST
    getAddressListSuccess(state, action) {
      state.isLoading = false;
      if (action && action.payload && action.payload.length > 0) {
        const addresses = action.payload.map((addressItem) => {
          const address = { ...addressItem };
          if (address.iso_country === 'AU') {
            address.country = 'Australia';
          } else if (address.iso_country === 'NZ') {
            address.country = 'New Zealand';
          } else if (address.iso_country === 'US') {
            address.country = 'United States';
          } else if (address.iso_country === 'CA') {
            address.country = 'Canada';
          } else if (address.iso_country === 'GB') {
            address.country = 'UK';
          } else if (address.iso_country === 'SG') {
            address.country = 'Singapore';
          } else if (address.iso_country === 'IE') {
            address.country = 'Ireland';
          } else {
            address.country = address.iso_country;
          }
          return address;
        });
        state.addressList = addresses;
      }
    },
    // UPDATE Address
    updateCurrentAddressSuccess(state, action) {
      state.isLoading = false;
      const updatedAddress = action.payload;
      state.addressList = state.addressList.map((address) =>
        address.sid === updatedAddress.sid ? updatedAddress : address
      );
    }
  }
});

// Reducer
export default slice.reducer;

export function getAddressList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getAddressesApi();
      dispatch(slice.actions.getAddressListSuccess(response.addresses));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// export function updateExsitingNumber(newNumber) {
//   return async (dispatch) => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await updateNumber(newNumber);
//       dispatch(slice.actions.updateCurrentNumberSuccess(response));
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }
