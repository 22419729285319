import { useLocation } from 'react-router-dom';
// material
import { MIconButton } from '../../components/@material-extend';
import { PATH_DASHBOARD } from '../../routes/paths';

const PenIcon = { image: '/static/icons/ic_help_center.svg' };
// ----------------------------------------------------------------------

const getCurrentTabURL = (pathname) => {
  let helpURL = 'https://help.cradle.io/knowledge';
  switch (pathname) {
    case PATH_DASHBOARD.calllog:
      helpURL = 'https://help.cradle.io/knowledge/calls';
      break;
    case PATH_DASHBOARD.users:
      helpURL = 'https://help.cradle.io/knowledge/how-can-i-invite-my-team';
      break;
    case PATH_DASHBOARD.roles.root:
      helpURL = 'https://help.cradle.io/knowledge/how-do-i-create-roles';
      break;
    case PATH_DASHBOARD.routing.root:
      helpURL = 'https://help.cradle.io/knowledge/how-do-i-route-calls';
      break;
    case PATH_DASHBOARD.settings.account:
      helpURL = 'https://help.cradle.io/knowledge/how-can-i-update-my-billing-information';
      break;
    case PATH_DASHBOARD.settings.numbers:
      helpURL = 'https://help.cradle.io/knowledge/how-do-i-set-up-my-numbers';
      break;
    case PATH_DASHBOARD.settings.integrations:
      helpURL = 'https://help.cradle.io/knowledge/integrations';
      break;
    case PATH_DASHBOARD.settings.billing:
      helpURL = 'https://help.cradle.io/knowledge/how-can-i-update-my-billing-information';
      break;
    case PATH_DASHBOARD.reporting.dashboard:
      helpURL = 'https://help.cradle.io/knowledge/whats-the-cradle-dashboard';
      break;
    case PATH_DASHBOARD.reporting.rolereports:
      helpURL = 'https://help.cradle.io/knowledge/what-is-the-role-report';
      break;
    case PATH_DASHBOARD.reporting.userreports:
      helpURL = 'https://help.cradle.io/knowledge/whats-the-cradle-dashboard';
      break;
    default:
      break;
  }
  return helpURL;
};

export default function HelpCenter() {
  const { pathname } = useLocation();
  const currentHelpURL = getCurrentTabURL(pathname);

  return (
    <>
      <MIconButton
        href={currentHelpURL}
        target="_blank"
        rel="noopener"
        sx={{
          padding: 0,
          width: 44,
          height: 44
        }}
      >
        <img src={PenIcon.image} aria-label="edit picture" width={24} height={24} />
      </MIconButton>
    </>
  );
}
