import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
// slices
import userReducer from './slices/user';
import rolesReducer from './slices/roles';
import reportReducer from './slices/report';
import conversationReducer from './slices/conversation';
import routingReducer from './slices/routing';
import numberReducer from './slices/number';
import addressReducer from './slices/address';
import billingReducer from './slices/billing';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: []
};

const rootReducer = combineReducers({
  user: userReducer,
  roles: rolesReducer,
  report: reportReducer,
  routing: routingReducer,
  number: numberReducer,
  address: addressReducer,
  billing: billingReducer,
  conversation: conversationReducer
});

export { rootPersistConfig, rootReducer };
