import { createSlice } from '@reduxjs/toolkit';
// utils
import { getNumbersApi, updateNumber } from '../../utils/CradleClient';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  numberList: []
};

const slice = createSlice({
  name: 'number',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET NUMBER LIST
    getNumberListSuccess(state, action) {
      state.isLoading = false;
      state.numberList = action.payload;
    },
    // UPDATE NUMBER
    updateCurrentNumberSuccess(state, action) {
      state.isLoading = false;
      const updatedNumber = action.payload;
      state.numberList = state.numberList.map((number) =>
        number.number === updatedNumber.number ? updatedNumber : number
      );
    }
  }
});

// Reducer
export default slice.reducer;

export function getNumberList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getNumbersApi();
      dispatch(slice.actions.getNumberListSuccess(response.numbers));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateExsitingNumber(newNumber) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await updateNumber(newNumber);
      dispatch(slice.actions.updateCurrentNumberSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
