import { createSlice } from '@reduxjs/toolkit';
// utils
import {
  loadRoleListApi,
  fetchRoleUsers,
  updateUserApi,
  getNumbersApi,
  putRoleUser,
  createNewRoleApi
} from '../../utils/CradleClient';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  roleList: [],
  roleUsers: [],
  numbers: [],
  notifications: null
};

const slice = createSlice({
  name: 'roles',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET MANAGE USERS
    getRolesListSuccess(state, action) {
      state.isLoading = false;
      state.roleList = action.payload;
    },
    // GET MANAGE ROLE USERS
    getRoleUserSuccess(state, action) {
      state.isLoading = false;
      state.roleUsers = action.payload;
    },

    // GET MANAGE NUMBERS
    getNumberSuccess(state, action) {
      state.isLoading = false;
      state.numbers = action.payload;
    },

    // UPDATE USER
    updateCurrentUserSuccess(state, action) {
      state.isLoading = false;
      // const userList = state.userList;
      const updatedUser = action.payload;
      state.userList = state.userList.map((user) => (user.id === updatedUser.id ? updatedUser : user));
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
// export const { onToggleFollow, deleteUser } = slice.actions;

// ----------------------------------------------------------------------
export function getRolesList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await loadRoleListApi();
      dispatch(slice.actions.getRolesListSuccess(response.roles));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getRoleUsers() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetchRoleUsers();
      dispatch(slice.actions.getRoleUserSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateRoleUser(newRoles) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await putRoleUser(newRoles);
      dispatch(slice.actions.getRoleUserSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getNumbers() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getNumbersApi();
      dispatch(slice.actions.getNumberSuccess(response.numbers));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createNewRole(userInfo) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await createNewRoleApi(userInfo);
      dispatch(slice.actions.getRolesListSuccess(response.users));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateExsitingUser(userInfo) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await updateUserApi(userInfo);
      dispatch(slice.actions.updateCurrentUserSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
