import { getAuth, getIdToken } from 'firebase/auth';

export const microsoftAuthCallBack = (accessToken) => {
  // if access token not return
  if (!accessToken) {
    // loadWebApp(false);
  }
  if (accessToken !== localStorage.access_token) {
    // seeds access token in localStorage
    localStorage.access_token = accessToken;
    // no reliable source for exp, testing reveals 60 mins for work, 24 hours for personal accounts, defaulting to 60 mins
    localStorage.exp = Date.now() + 3600000;
  }
};

export const getToken = async (type) => {
  console.log(`get token: ${type}`);
  const auth = getAuth();
  const { currentUser } = auth;
  if (currentUser) {
    const token = await getIdToken(currentUser, true);
    return token;
  }
  throw new Error('Unable to get access token');
};

export async function getTokenPromise() {
  const auth = getAuth();
  const { currentUser } = auth;
  if (currentUser) {
    const token = await getIdToken(currentUser, true);
    return token;
  }
  throw new Error('Unable to get access token');
}
