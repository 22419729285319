import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment-timezone';
// utils
import { fetchConversations } from '../../utils/CradleClient';
import { resolveFromAndUntilFilter, defaultFilter } from '../../pages/dashboard/calls/ConversationList';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  conversationList: [],
  stats: {
    count: 0,
    totalDurationSeconds: 0,
    groupedBy: 'day'
  }
};

const slice = createSlice({
  name: 'conversation',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET BOARD
    getConversationListSuccess(state, action) {
      state.isLoading = false;
      state.conversationList = action.payload.conversations;
      state.stats = action.payload.stats;
    },

    getConversationStatsSuccess(state, action) {
      state.isLoading = false;
      state.stats = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

export function getConversationList(newFilter) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let filter = newFilter;
      let { from, until } = filter;
      if (filter.dateRange !== 'custom') {
        [from, until] = resolveFromAndUntilFilter(filter.dateRange);
      }
      const [nature, status] = getNatureAndStatusFromOutcomes(filter.outcomes);

      let user = '';
      let role = '';
      if (filter.users && filter.users.length) {
        // user = filter.users.map((user) => user.id).join(',');
        user = filter.users.join(',');
      }
      if (filter.roles && filter.roles.length) {
        // role = filter.roles.map((role) => role.id).join(',');
        role = filter.roles.join(',');
      }

      let pageSize = filter.pageSize ? filter.pageSize : defaultFilter.pageSize;
      let apiFilter = {
        page: filter.page,
        from,
        until,
        nature,
        status,
        user,
        role,
        pageSize,
        timezone: moment.tz.guess(),
        dateRange: filter.dateRange
      };
      const response = await fetchConversations(apiFilter);
      dispatch(slice.actions.getConversationListSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

function getNatureAndStatusFromOutcomes(outcomes) {
  let nature = '';
  let status = '';

  if (outcomes && outcomes.length) {
    let natureArr = [];
    let statusArr = [];

    outcomes.forEach((outcome) => {
      switch (outcome) {
        case 'answered':
          natureArr.push('in');
          statusArr.push('successful', 'forwarded');
          break;
        case 'missed':
          natureArr.push('in');
          statusArr.push('missed');
          break;
        case 'voicemail':
          natureArr.push('in');
          statusArr.push('voicemail');
          break;
        case 'outbound':
          natureArr.push('out');
          statusArr.push('completed');
          break;
        case 'team':
          natureArr.push('team');
          break;

        default:
          break;
      }
    });

    natureArr = Array.from(new Set(natureArr));
    nature = natureArr.join(',');
    statusArr = Array.from(new Set(statusArr));
    status = statusArr.join(',');
  }
  return [nature, status];
}
