export function orgID() {
  return typeof localStorage !== 'undefined' ? localStorage.org : '';
}
export const baseURL = process.env.REACT_APP_API_URL;
export const DOMAIN_URL = process.env.REACT_APP_DOMAIN_URL;
export const MICROSOFT_AUTH_ID = process.env.REACT_APP_MICROSOFT_AUTH_ID;
export const GOOGLE_AUTH_ID = process.env.REACT_APP_GOOGLE_AUTH_ID;
export const STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY;
export const HUBSPOT_INTERGRATION_LINK = process.env.REACT_APP_HUBSPOT_INTERGRATION_LINK;

export const BLOCKED_LIST = [
  'PS',
  'RU',
  'KP',
  'CU',
  'IR',
  'SY',
  'VE',
  'KZ',
  'AF',
  'BY',
  'BO',
  'KH',
  'CN',
  'ER',
  'LA',
  'NI',
  'YE',
  'ZW',
  'LY',
  'SD',
  'NG',
  'PK',
  'ID',
  'RO',
  'JO',
  'MM',
  'TM',
  'KG',
  'MN',
  'IQ',
  'OM',
  'SO',
  'KE',
  'MD'
];

export const orgsURL = `${baseURL}orgs`;
export const getCurrentOrgURL = `${baseURL}orgs/current`;

export function getBaseSubmitSupportTicketURL(subject) {
  let supportUrl = `https://help.cradle.io/knowledge/kb-tickets/new?email=${localStorage.email}&firstname=${localStorage.firstName}&lastname=${localStorage.lastName}`;
  if (subject) {
    supportUrl = `${supportUrl} &subject= ${subject}`;
  }
  return supportUrl;
}

export function getCurrentUserURL() {
  return `${baseURL}users/current`;
}

export function currentOrgURL() {
  return `${orgsURL}/${localStorage.org}`;
}

export function currentRoutingsURL() {
  return `${currentOrgURL()}/routingschemes`;
}

export function routingSchemeURL(schemeID) {
  return `${currentOrgURL()}/routingschemes/${schemeID}`;
}

export function routingSchemeModeURL(schemeID, type, mode) {
  return `${routingSchemeURL(schemeID)}/callroutes/${type}/modes/${mode}`;
}

export function routingSchemeMsgURL(schemeID, type, mode) {
  return `${routingSchemeModeURL(schemeID, type, mode)}/message`;
}

export function groupsURL(groupId, userId) {
  return `${currentOrgURL()}/roles/${groupId}/users/${userId}`;
}

export function roleVoicemaillURL(roleID) {
  return `${currentOrgURL()}/roles/${roleID}/voicemailmessage`;
}
export function hubspotAuth(defaultCountryCode) {
  if (!defaultCountryCode) {
    defaultCountryCode = 'NZ';
  }
  return `${currentOrgURL()}/integrations/hubspot/authorize?defaultCountryCode=${defaultCountryCode}`;
}

export function hubspotRedirect(params) {
  return `${currentOrgURL()}/integrations/hubspot/authorize/redirect${params}`;
}

export function numbersURL() {
  return `${currentOrgURL()}/numbers`;
}

export function integrationStatusURL() {
  return `${currentOrgURL()}/integrations`;
}

export function xeroRedirect(params) {
  return `${currentOrgURL()}/integrations/xero/authorize/redirect?${params}`;
}

export function xpmRedirect(params) {
  return `${currentOrgURL()}/integrations/xpm/authorize/redirect?${params}`;
}

export function dashboardReportURL() {
  return `${currentOrgURL()}/reports/summary`;
}
