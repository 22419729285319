import 'whatwg-fetch';

import {
  currentOrgURL,
  groupsURL,
  routingSchemeModeURL,
  getCurrentUserURL,
  currentRoutingsURL,
  routingSchemeURL,
  hubspotRedirect,
  numbersURL,
  integrationStatusURL,
  dashboardReportURL,
  routingSchemeMsgURL
} from './Constants';
import { debugLog, debugError } from './Debug';
import { getTokenPromise } from './tokenUtils';

export function roleUsersUrl() {
  return `${currentOrgURL()}/roles/users`;
}
export function rolesUrl() {
  return `${currentOrgURL()}/roles?limit=50`;
}
export function roleUrl(id) {
  return `${currentOrgURL()}/roles/${id}`;
}
export function conversationsUrl() {
  return `${currentOrgURL()}/conversations`;
}

export function fetchReq(url) {
  return getTokenPromise().then(
    (token) => {
      debugLog('fetch req before fetch');
      return fetch(url, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        credentials: 'same-origin'
      })
        .then(
          (response) => {
            debugLog('fetchReq', url, 'response', response);
            return response;
          },
          (error) => {
            throw error;
          }
        )
        .catch((error) => {
          debugLog('fetchReq', url, 'catch', error);
          throw error;
        });
    },
    (error) => {
      throw error;
    }
  );
}

export function synReq(url, body) {
  return getTokenPromise().then(
    (token) => {
      debugLog('synReq req before fetch');
      return fetch(url, {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(body)
      })
        .then(
          (response) => {
            debugLog('synReq', url, 'response', response);
            if (response.status <= 299) {
              return response;
            }
            throw response.status;
          },
          (error) => {
            debugLog('synReq', url, 'error', error);
            throw error;
          }
        )
        .catch((error) => {
          debugLog('synReq', url, 'catch', error);
          throw error;
        });
    },
    (error) => {
      throw error;
    }
  );
}

export function postReq(url, body) {
  return getTokenPromise().then(
    (token) => {
      debugLog('postReq req before fetch');
      return fetch(url, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(body)
      })
        .then(
          (response) => {
            debugLog('postReq', url, 'response', response);
            return response;
          },
          (error) => {
            throw error;
          }
        )
        .catch((error) => {
          debugLog('postReq', url, 'catch', error);
          throw error;
        });
    },
    (error) => {
      throw error;
    }
  );
}

export function delReq(url, body) {
  return getTokenPromise().then(
    (token) => {
      debugLog('delReq req before fetch');
      return fetch(url, {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(body)
      })
        .then(
          (response) => {
            if (response.status <= 299) {
              debugLog('delReq', url, 'response', response);
              return response;
            }
            throw response.status;
          },
          (error) => {
            debugLog('delReq', url, 'error', error);
            throw error;
          }
        )
        .catch((error) => {
          debugError('delReq', url, 'catch', error);
          throw error;
        });
    },
    (error) => {
      throw error;
    }
  );
}

export function delRoutingReq(url, body) {
  return getTokenPromise().then(
    (token) => {
      debugLog('delReq req before fetch');
      return fetch(url, {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(body)
      })
        .then(
          (response) => {
            if (response.status <= 299) {
              debugLog('delReq', url, 'response', response);
              return response;
            }
            if (response.status === 400) {
              return response;
            }
            throw response.status;
          },
          (error) => {
            debugLog('delReq', url, 'error', error);
            throw error;
          }
        )
        .catch((error) => {
          debugError('delReq', url, 'catch', error);
          throw error;
        });
    },
    (error) => {
      throw error;
    }
  );
}

export function fetchRoleUsers() {
  const url = roleUsersUrl();
  return fetchReq(url).then((response) => {
    if (response.status === 200) {
      return response.json();
    }
    throw response.statusText;
  });
}

export function fetchRoles() {
  const url = rolesUrl();
  return fetchReq(url)
    .then((response) => {
      if (response.status === 200) {
        return response.json();
      }
      throw response.statusText;
    })
    .then((data) => data.roles);
}

export function fetchConversations(filter) {
  let url = conversationsUrl();
  let filterPath = '?';
  if (filter) {
    if (filter.from && filter.until) {
      filterPath = `${filterPath}from=${filter.from}&until=${filter.until}&`;
    }
    if (filter.status) {
      filterPath = `${filterPath}status=${filter.status}&`;
    }
    if (filter.nature) {
      filterPath = `${filterPath}nature=${filter.nature}&`;
    }
    if (filter.role) {
      filterPath = `${filterPath}role=${filter.role}&`;
    }
    if (filter.user) {
      filterPath = `${filterPath}user=${filter.user}&`;
    }
    if (filter.page > 1) {
      filterPath = `${filterPath}page=${filter.page}&`;
    }
    if (filter.pageSize > 1) {
      filterPath = `${filterPath}pageSize=${filter.pageSize}&`;
    }
    if (filter.timezone) {
      filterPath = `${filterPath}timezone=${filter.timezone}&`;
    }
  }
  url = `${url}${filterPath}`;
  return fetchReq(url)
    .then((response) => {
      if (response.status === 200) {
        return response.json();
      }
      throw response.statusText;
    })
    .then((data) => data);
}

export function createNewUserApi(newUserInfo) {
  const userUrl = `${currentOrgURL()}/users`;
  return postReq(userUrl, newUserInfo).then((response) => {
    if (response.status === 200) {
      return response.json();
    }
    throw response;
  });
}

export function updateUserApi(newUserInfo) {
  const userUrl = `${currentOrgURL()}/users/${newUserInfo.id}`;
  return synReq(userUrl, newUserInfo).then((response) => {
    if (response.status === 200) {
      return response.json();
    }
    throw response;
  });
}

export function getCurrentUserApi() {
  const userUrl = getCurrentUserURL();
  return fetchReq(userUrl).then((response) => {
    if (response.status === 200) {
      return response.json();
    }
    throw response;
  });
}

export function getNumbersApi() {
  const numberURL = `${currentOrgURL()}/numbers`;
  return fetchReq(numberURL).then((response) => {
    debugLog('getNumbers', response, response.status);
    if (response.status === 200) {
      console.log(response);
      return response.json();
    }
    throw response.statusText;
  });
}

export function getAddressesApi() {
  const addressURL = `${currentOrgURL()}/addresses`;
  return fetchReq(addressURL).then((response) => {
    debugLog('getAddressesApi', response, response.status);
    if (response.status === 200) {
      console.log(response);
      return response.json();
    }
    throw response.statusText;
  });
}

export function getBillingInfoApi() {
  const billingURL = `${currentOrgURL()}/billing`;
  return fetchReq(billingURL).then((response) => {
    debugLog('getBillingInfoApi', response, response.status);
    if (response.status === 200) {
      console.log(response);
      return response.json();
    }
    throw response.statusText;
  });
}

export function loadTeamMember() {
  const userUrl = `${currentOrgURL()}/users?limit=200`;
  return fetchReq(userUrl).then((response) => {
    debugLog('loadTeamMember', response, response.status);
    if (response.status === 200) {
      console.log(response);
      return response.json();
    }
    throw response.statusText;
  });
}
export function loadRoleListApi() {
  const userUrl = `${currentOrgURL()}/roles`;
  return fetchReq(userUrl).then((response) => {
    debugLog('loadRoleListApi', response, response.status);
    if (response.status === 200) {
      console.log(response);
      return response.json();
    }
    throw response.statusText;
  });
}
export function loadRoutingListApi() {
  const routingListUrl = currentRoutingsURL();
  return fetchReq(routingListUrl).then((response) => {
    debugLog('loadRoutingListApi', response, response.status);
    if (response.status === 200) {
      console.log(response);
      return response.json();
    }
    throw response.statusText;
  });
}

export function updateRouteApiWithCondition(routingId, routeType, mode, newRouteInfo) {
  const routeUrl = routingSchemeModeURL(routingId, routeType, mode);
  return postReq(routeUrl, newRouteInfo).then((response) => {
    if (response.status === 200) {
      return response.json();
    }
    throw response;
  });
}

export function updateRouteApiWithAudioFile(routingId, routeType, mode, file) {
  const routeUrl = routingSchemeMsgURL(routingId, routeType, mode);
  return postReq(routeUrl, file).then((response) => {
    if (response.status === 200) {
      return response.json();
    }
    throw response;
  });
}

export function putRoleUser(roleID, userId, priority = 0) {
  return synReq(groupsURL(roleID, userId), { priority }).then((response) => {
    if (response.status === 200) {
      return response;
    }
    throw response.statusText;
  });
}
export function createNewRoleApi(newRoleInfo) {
  const roleURL = `${currentOrgURL()}/roles`;
  return postReq(roleURL, newRoleInfo).then((response) => {
    if (response.status === 200) {
      return response.json();
    }
    throw response;
  });
}

export function createNewRouteApi(newRouteInfo) {
  const roleURL = `${currentOrgURL()}/routingschemes`;
  return postReq(roleURL, newRouteInfo).then((response) => {
    if (response.status === 200) {
      return response.json();
    }
    throw response;
  });
}
export function delRoleUser(roleId, userId) {
  return delReq(groupsURL(roleId, userId)).then((response) => {
    if (response.status === 200) {
      return response;
    }
    throw response.statusText;
  });
}

export function deleteRole(roleId) {
  const roleURL = `${currentOrgURL()}/roles/${roleId}`;
  return delReq(roleURL).then((response) => response);
}

export function deleteRoute(routeId) {
  const routeURL = `${currentOrgURL()}/routingschemes/${routeId}`;
  return delRoutingReq(routeURL).then((response) => {
    if (response.status === 200) {
      return response;
    }
    if (response.status === 400) {
      return response.json();
    }
    throw response.statusText;
  });
}

export function deleteRule(ruleId) {
  const ruleURL = `${currentOrgURL()}/routingschemes/${ruleId}`;
  return delReq(ruleURL).then((response) => {
    console.log(response);
    return response;
    // if (response.status === 200) {
    //   return response.json();
    // }
    // throw response.statusText;
  });
}

export function updateRole(roleId, role) {
  const roleURL = `${currentOrgURL()}/roles/${roleId}`;
  return synReq(roleURL, role).then((response) => {
    if (response.status === 200) {
      return response.json();
    }
    throw response.statusText;
  });
}

export function updateRoleVM(roleId, vmURL) {
  const roleURL = `${currentOrgURL()}/roles/${roleId}/voicemailmessage`;
  return postReq(roleURL, vmURL).then((response) => {
    if (response.status === 200) {
      return response.json();
    }
    throw response;
  });
}

export function loadRoutes(routingId, type, mode) {
  return fetchReq(routingSchemeModeURL(routingId, type, mode)).then((response) => {
    if (response.status === 200) {
      console.log(response);
      return response.json();
    }
    throw response.statusText || response.status;
  });
}
// export function loadDashboardReport() {
//   const reportURL = `${currentOrgURL()}/reports/summary`;

//   return fetchReq(reportURL).then((response) => {
//     if (response.status <= 299) {
//       return response;
//     }
//     throw response.statusText || response.status;
//   });
// }
export function loadRoles() {
  return fetchReq(`${currentOrgURL()}/roles`);
}

export function loadOrg() {
  return fetchReq(currentOrgURL());
}

export function loadRoutings() {
  return fetchReq(currentRoutingsURL());
}

export function updateRouting(routingID, newRouting) {
  const routingUrl = routingSchemeURL(routingID);
  return synReq(routingUrl, newRouting).then((response) => {
    if (response.status === 200) {
      return response.json();
    }
    throw response;
  });
}

export function isAdmin(orgID) {
  console.log(orgID);
  return fetchReq(getCurrentUserURL())
    .then((response) => response.json())
    .then((data) => {
      localStorage.created = data.created;
      localStorage.email = data.email;
      localStorage.name = `${data.firstName} ${data.lastName}`;
      localStorage.firstName = data.firstName;
      localStorage.lastName = data.lastName;
      localStorage.org = orgID;
      localStorage.userId = data.id;
      return data.isAdmin && data.status === 'activated';
    });
}

export function crmOauth(params) {
  return fetchReq(hubspotRedirect(params));
}

export function getNumbers() {
  return fetchReq(numbersURL())
    .then((response) => response.json())
    .then((data) => data.numbers);
}

export function updateNumber(number) {
  return synReq(`${currentOrgURL()}/numbers/:${number.number}`, number).then((response) => response.json());
}

export function addNewNumber(number) {
  return postReq(numbersURL(), number).then((response) => response.json());
}

export function requestIntegrationStatus() {
  return fetchReq(integrationStatusURL()).then((response) => response.json());
}

export function loadDashboardReports() {
  return fetchReq(dashboardReportURL())
    .then((response) => response.json())
    .then((data) => {
      localStorage.reportEXP = data.exp;
      return data.url;
    });
}

export function loadGroupReports(reportUrl) {
  return fetchReq(reportUrl)
    .then((response) => response.json())
    .then((data) => {
      localStorage.reportEXP = data.exp;
      return data.url;
    });
}

export function loadPeopleReports(peopleUrl) {
  return fetchReq(peopleUrl)
    .then((response) => response.json())
    .then((data) => {
      localStorage.reportEXP = data.exp;
      return data.url;
    });
}

export async function getHubspotVisitorIdentify() {
  const visitorURL = `${currentOrgURL()}/crm/hubspot/visitor/identify`;
  return postReq(visitorURL).then((response) => {
    if (response.status === 200) {
      return response.json();
    }
    throw response;
  });
}
