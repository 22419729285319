import { map, filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// utils
import {
  loadTeamMember,
  createNewUserApi,
  updateUserApi,
  getNumbersApi,
  getCurrentUserApi
} from '../../utils/CradleClient';
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  myProfile: null,
  posts: [],
  users: [],
  userList: [],
  followers: [],
  friends: [],
  gallery: [],
  cards: null,
  addressBook: [],
  invoices: [],
  numbers: [],
  notifications: null,
  currentUser: null
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PROFILE
    getProfileSuccess(state, action) {
      state.isLoading = false;
      state.myProfile = action.payload;
    },

    // GET POSTS
    getPostsSuccess(state, action) {
      state.isLoading = false;
      state.posts = action.payload;
    },

    // GET USERS
    getUsersSuccess(state, action) {
      state.isLoading = false;
      state.users = action.payload;
    },

    // GET CURRENT USER
    getCurrentUserSuccess(state, action) {
      state.isLoading = false;
      state.currentUser = action.payload;
    },

    // DELETE USERS
    deleteUser(state, action) {
      const deleteUser = filter(state.userList, (user) => user.id !== action.payload);
      const filterValidUsers = deleteUser.filter((u) => u.email && u.id && u.status !== 'disabled');
      state.userList = filterValidUsers;
    },

    // GET FOLLOWERS
    getFollowersSuccess(state, action) {
      state.isLoading = false;
      state.followers = action.payload;
    },

    // ON TOGGLE FOLLOW
    onToggleFollow(state, action) {
      const followerId = action.payload;

      const handleToggle = map(state.followers, (follower) => {
        if (follower.id === followerId) {
          return {
            ...follower,
            isFollowed: !follower.isFollowed
          };
        }
        return follower;
      });

      state.followers = handleToggle;
    },

    // GET FRIENDS
    getFriendsSuccess(state, action) {
      state.isLoading = false;
      state.friends = action.payload;
    },

    // GET GALLERY
    getGallerySuccess(state, action) {
      state.isLoading = false;
      state.gallery = action.payload;
    },

    // GET MANAGE USERS
    getUserListSuccess(state, action) {
      state.isLoading = false;
      const filterValidUsers = action.payload.filter((u) => u.email && u.id && u.status !== 'disabled');
      // const filterValidUsers = action.payload.filter((u) => u.email && u.id);
      state.userList = filterValidUsers;
    },

    // GET MANAGE NUMBERS
    getNumberSuccess(state, action) {
      state.isLoading = false;
      state.numbers = action.payload;
    },

    // UPDATE USER
    updateCurrentUserSuccess(state, action) {
      state.isLoading = false;
      // const userList = state.userList;
      const updatedUser = action.payload;
      state.userList = state.userList.map((user) => (user.id === updatedUser.id ? updatedUser : user));
    },

    // GET CARDS
    getCardsSuccess(state, action) {
      state.isLoading = false;
      state.cards = action.payload;
    },

    // GET ADDRESS BOOK
    getAddressBookSuccess(state, action) {
      state.isLoading = false;
      state.addressBook = action.payload;
    },

    // GET INVOICES
    getInvoicesSuccess(state, action) {
      state.isLoading = false;
      state.invoices = action.payload;
    },

    // GET NOTIFICATIONS
    getNotificationsSuccess(state, action) {
      state.isLoading = false;
      state.notifications = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { onToggleFollow, deleteUser } = slice.actions;

// ----------------------------------------------------------------------

export function getUserList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await loadTeamMember();
      dispatch(slice.actions.getUserListSuccess(response.users));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCurrentUser() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getCurrentUserApi();
      dispatch(slice.actions.getCurrentUserSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getNumbers() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getNumbersApi();
      dispatch(slice.actions.getNumberSuccess(response.numbers));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createNewUser(userInfo) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await createNewUserApi(userInfo);
      dispatch(slice.actions.getUserListSuccess(response.users));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateExsitingUser(userInfo) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await updateUserApi(userInfo);
      dispatch(slice.actions.updateCurrentUserSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateDisabledUser(userInfo) {
  return (dispatch) => {
    dispatch(slice.actions.updateCurrentUserSuccess(userInfo));
  };
}
