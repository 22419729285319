import { createSlice } from '@reduxjs/toolkit';
// utils
import { getBillingInfoApi } from '../../utils/CradleClient';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  billingInfo: {}
};

const slice = createSlice({
  name: 'billing',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET BillingInfo LIST
    getBillingInfoSuccess(state, action) {
      state.isLoading = false;
      state.billingInfo = action.payload;
    },
    // UPDATE BillingInfo
    updateCurrentBillingInfoSuccess(state, action) {
      state.isLoading = false;
      const updatedAddress = action.payload;
      state.billingInfo = state.addressList.map((address) =>
        address.sid === updatedAddress.sid ? updatedAddress : address
      );
    }
  }
});

// Reducer
export default slice.reducer;

export function getBillingInfo() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getBillingInfoApi();
      dispatch(slice.actions.getBillingInfoSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// export function updateExsitingNumber(newNumber) {
//   return async (dispatch) => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await updateNumber(newNumber);
//       dispatch(slice.actions.updateCurrentNumberSuccess(response));
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }
