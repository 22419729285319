import { createSlice } from '@reduxjs/toolkit';
// utils
import {
  loadRoutingListApi,
  createNewRouteApi,
  updateRouting,
  loadRoutes,
  updateRouteApiWithCondition
} from '../../utils/CradleClient';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  routingList: [],
  ivrRouteOpen: {},
  userRouteOpen: {},
  vmRouteOpen: {},
  roleRouteOpen: {},
  numberRouteOpen: {},
  ivrRouteClosed: {},
  userRouteClosed: {},
  vmRouteClosed: {},
  roleRouteClosed: {},
  numberRouteClosed: {}
};

const slice = createSlice({
  name: 'routing',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET BOARD
    getRoutingListSuccess(state, action) {
      state.isLoading = false;
      state.routingList = action.payload;
    },

    updateCurrentRoutingSuccess(state, action) {
      state.isLoading = false;
      const updatedRouting = action.payload;
      state.routingList = state.routingList.map((route) => (route.id === updatedRouting.id ? updatedRouting : route));
    },

    getRoutesWithConditionsSuccess(state, action) {
      state.isLoading = false;
      if (action.payload.mode === 'open') {
        switch (action.payload.routeType) {
          case 'ivr':
            state.ivrRouteOpen = action.payload;
            break;
          case 'role':
            state.roleRouteOpen = action.payload;
            break;
          case 'user':
            state.userRouteOpen = action.payload;
            break;
          case 'mail':
            state.vmRouteOpen = action.payload;
            break;
          case 'number':
            state.numberRouteOpen = action.payload;
            break;
          default:
            state.ivrRouteOpen = action.payload;
            break;
        }
      } else {
        switch (action.payload.routeType) {
          case 'ivr':
            state.ivrRouteClosed = action.payload;
            break;
          case 'role':
            state.roleRouteClosed = action.payload;
            break;
          case 'user':
            state.userRouteClosed = action.payload;
            break;
          case 'mail':
            state.vmRouteClosed = action.payload;
            break;
          case 'number':
            state.numberRouteClosed = action.payload;
            break;
          default:
            state.ivrRouteClosed = action.payload;
            break;
        }
      }
    }
  }
});

// Reducer
export default slice.reducer;

export function getRoutingList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await loadRoutingListApi();
      dispatch(slice.actions.getRoutingListSuccess(response.RoutingSchemes));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getRoutesWithConditions(routingId, type, mode) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await loadRoutes(routingId, type, mode);
      const responseWithType = { ...response, routeType: type };
      dispatch(slice.actions.getRoutesWithConditionsSuccess(responseWithType, mode));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateRouteWithConditions(routingId, routeType, mode, newRouteInfo) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await updateRouteApiWithCondition(routingId, routeType, mode, newRouteInfo);
      const responseWithType = { ...response, routeType };
      dispatch(slice.actions.getRoutesWithConditionsSuccess(responseWithType, mode));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createNewRoute(routeInfo) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await createNewRouteApi(routeInfo);
      dispatch(slice.actions.getRoutingListSuccess(response.RoutingSchemes));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateExsitingRoute(routeInfo) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await updateRouting(routeInfo.id, routeInfo);
      dispatch(slice.actions.updateCurrentRoutingSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
