import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer, useState } from 'react';

import { initializeApp } from 'firebase/app';
import { getPerformance } from 'firebase/performance';
import {
  getAuth,
  GoogleAuthProvider,
  OAuthProvider,
  signInWithPopup,
  linkWithPopup,
  signOut,
  fetchSignInMethodsForEmail,
  sendSignInLinkToEmail
} from 'firebase/auth';
import { isAdmin, getHubspotVisitorIdentify, getAddressesApi } from '../utils/CradleClient';

// import firebase from 'firebase/compat/app';
// import 'firebase/compat/firestore';
// import 'firebase/compat/auth';
// import { getCurrentUserURL } from '../utils/Constants';
import { getToken } from '../utils/tokenUtils';
import { DOMAIN_URL, baseURL, currentOrgURL } from '../utils/Constants';
import { PATH_AUTH, PATH_ERROR, SETUP_STEPPER } from '../routes/paths';

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

let app = initializeApp(config);
const perf = getPerformance(app);
console.log('FirebaseContext perf:', perf);

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;
    return {
      ...state,
      isInitialized: true,
      isAuthenticated: true,
      user
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      user
    };
  }
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'firebase',
  login: () => Promise.resolve(),
  register: () => Promise.resolve(),
  logout: () => Promise.resolve()
});

AuthProvider.propTypes = {
  children: PropTypes.node
};

const getDomainFromEmail = (email) => {
  const domainMatch = /@(\w+)/.exec(email);
  return domainMatch && domainMatch.length > 0 ? domainMatch[1] : '';
};

function AuthProvider({ children }) {
  const [profile, setProfile] = useState(null);
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      // detaching the listener
      if (user) {
        const providerInfo = user.providerData;
        if (providerInfo && providerInfo.length > 0) {
          const currentProvider = providerInfo[0];
          if (currentProvider && currentProvider.providerId) {
            if (currentProvider.providerId === 'google.com') {
              localStorage.type = 'google';
            } else if (currentProvider.providerId === 'microsoft.com') {
              localStorage.type = 'microsoft';
            }
          }
        }
        const firebaseIdToken = await getToken();
        auth.currentUser
          .getIdTokenResult()
          .then((idTokenResult) => {
            // Confirm the user is an Admin.
            console.log(idTokenResult.claims);
            if (
              idTokenResult &&
              idTokenResult.claims &&
              idTokenResult.claims.userSetUp &&
              idTokenResult.claims.userSetUp !== ''
            ) {
              window.localStorage.userSetUp = idTokenResult.claims.userSetUp;
              isAdmin(orgID)
                .then(async (isAdmin) => {
                  if (!window.location.href.includes(SETUP_STEPPER)) {
                    const addressResponse = await getAddressesApi();
                    if (addressResponse && addressResponse.addresses && addressResponse.addresses.length > 0) {
                      const billingURL = `${currentOrgURL()}/billing`;
                      fetch(billingURL, {
                        headers: {
                          Accept: 'application/json',
                          'Content-Type': 'application/json',
                          Authorization: `Bearer ${firebaseIdToken || getToken()}`
                        }
                      })
                        .then((result) => {
                          if (!result.ok && result.status === 404) {
                            window.location.href = `${SETUP_STEPPER}/billing`;
                            throw new Error('error');
                            // eslint-disable-next-line no-else-return
                          } else {
                            return result.json();
                          }
                        })
                        .then(async (billingResponse) => {
                          console.log(billingResponse);
                          if (billingResponse && billingResponse.card && billingResponse.card.cvcCheck === 'pass') {
                            window.location.href = `${SETUP_STEPPER}/final`;
                          }
                        })
                        .catch((err) => {
                          console.log(err);
                        });
                    } else {
                      // step address
                      window.location.href = `${SETUP_STEPPER}/address`;
                    }
                  } else if (isAdmin) {
                    const user = {
                      displayName: 'Test'
                    };
                    dispatch({
                      type: 'INITIALIZE',
                      payload: {
                        isAuthenticated: true,
                        user
                      }
                    });
                  }
                })
                .catch((error) => {
                  console.log(error);
                  if (error && error.message && error.message.includes('Org not signed up')) {
                    if (!window.location.href.includes(SETUP_STEPPER)) {
                      window.location.href = `${SETUP_STEPPER}/company`;
                    }
                  }
                });
            } else {
              isAdmin(orgID).then((isAdmin) => {
                if (isAdmin) {
                  const user = {
                    displayName: 'Test'
                  };
                  dispatch({
                    type: 'INITIALIZE',
                    payload: {
                      isAuthenticated: true,
                      user
                    }
                  });
                }
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
        const orgID = window.localStorage.getItem('org');
        if (firebaseIdToken && orgID) {
          getHubspotVisitorIdentify().then((data) => {
            if (data) {
              localStorage.hubspotVisitorToken = data;
              window.hsConversationsSettings = {
                loadImmediately: false,
                identificationEmail: localStorage.email,
                identificationToken: data
              };
              const status = window.HubSpotConversations.widget.status();
              if (status.loaded) {
                window.HubSpotConversations.widget.refresh();
              } else {
                window.HubSpotConversations.widget.load();
              }
            }
          });
          // const response = await axios.get('/api/account/my-account');
          // const { user } = response.data;
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } else {
        console.log('already logged out');
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
        // No user is signed in...code to handle unauthenticated users.
      }
    });
    return () => unsubscribe(); // unsubscribing from the listener when the component is unmounting.
  }, []);

  const onSignIn = (result) => {
    fetch(`${baseURL}orgs/current`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${result}`
      }
    })
      .then((result) => {
        if (result.status === 404 || result.status === 500 || result.status === 403) {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
          window.location.href = PATH_ERROR.nonuser;
          return;
        }
        if (result.status === 401) {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
          window.location.href = PATH_ERROR.nonadmin;
        }
        return result.json();
      })
      .then((data) => {
        if (!data) {
          localStorage.clear();
          return;
        }
        localStorage.org = data.id;
        isAdmin(data.id).then((isAdmin) => {
          if (isAdmin) {
            localStorage.domain = data.domain;
            const user = {
              displayName: 'Test'
            };
            dispatch({
              type: 'LOGIN',
              payload: {
                user
              }
            });
          } else {
            localStorage.clear();
            dispatch({
              type: 'INITIALIZE',
              payload: {
                isAuthenticated: false,
                user: null
              }
            });
            window.location.href = PATH_ERROR.nonadmin;
          }
        });
      });
  };

  const login = (type) => {
    if (type === 'google') {
      const provider = new GoogleAuthProvider();
      provider.setCustomParameters({ prompt: 'select_account' });
      const auth = getAuth();
      signInWithPopup(auth, provider)
        .then((result) => {
          console.log(result);
          let { _tokenResponse } = result;
          let { idToken } = _tokenResponse;
          // const token = currentUaer.multiFactor.user.accessToken;
          onSignIn(idToken);
          // var credential = result.credential;
          // var token = credential.accessToken;
          // var user = result.user;
        })
        .catch((error) => {
          // Handle Errors here.
          console.log(error);
          if (error && error.code && error.code === 'auth/account-exists-with-different-credential') {
            const auth = getAuth();
            linkWithPopup(auth.currentUser, provider)
              .then((result) => {
                // Accounts successfully linked.
                let { _tokenResponse } = result;
                let { idToken } = _tokenResponse;
                // const token = currentUaer.multiFactor.user.accessToken;
                onSignIn(idToken);
                // const credential = provider.credentialFromResult(result);
                // let { accessToken } = credential;
                // const { user } = result.user;
                // ...
              })
              .catch((error) => {
                console.log(error);
                // Handle Errors here.
                // ...
              });
          }
        });
    } else {
      const provider = new OAuthProvider('microsoft.com');
      provider.setCustomParameters({ prompt: 'select_account' });
      const auth = getAuth();
      signInWithPopup(auth, provider)
        .then((result) => {
          console.log(result);
          const { _tokenResponse } = result;
          onSignIn(_tokenResponse.idToken);
        })
        .catch((error) => {
          // Handle Errors here.
          console.log(error);
          if (error && error.code && error.code === 'auth/account-exists-with-different-credential') {
            const { customData } = error;
            const { email } = customData;
            fetchSignInMethodsForEmail(auth, email).then((methods) => {
              console.log(methods);
              const actionCodeSettings = {
                url: `${DOMAIN_URL}/${PATH_AUTH.link}/ms`,
                handleCodeInApp: true
              };
              const auth = getAuth();
              sendSignInLinkToEmail(auth, email, actionCodeSettings)
                .then(() => {
                  window.localStorage.setItem('emailForSignIn', email);
                  window.location.href = PATH_ERROR.linkrequired;
                })
                .catch((error) => {
                  const errorCode = error.code;
                  const errorMessage = error.message;
                  console.log('FirebaseContext sendSignInLinkToEmail error:');
                  console.log(errorCode, errorMessage);
                  // ...
                });
            });
          }
        });
    }
  };

  const submitSignup = async () => {
    window.localStorage.country = 'NZ';
    window.location.href = `${SETUP_STEPPER}/company`;
    localStorage.type = window.localStorage.signupType;
  };

  const fullfilUserInfo = (user) => {
    const domain = getDomainFromEmail(user.email);
    window.localStorage.businessName = domain;
    window.localStorage.email = user.email;
    if (user && user.displayName) {
      if (user.displayName.includes(' ')) {
        const array = user.displayName.split(' ');
        let [firstName, lastName] = array;
        window.localStorage.firstName = firstName;
        window.localStorage.lastName = lastName;
      } else {
        window.localStorage.firstName = user.displayName;
        window.localStorage.lastName = user.displayName;
      }
    }
    window.localStorage.imageURL = user.photoURL;
  };

  const register = async (providerType) => {
    if (providerType === 'google') {
      const provider = new GoogleAuthProvider();
      provider.setCustomParameters({ prompt: 'select_account' });
      const auth = getAuth();
      signInWithPopup(auth, provider)
        .then((result) => {
          console.log(result);
          window.localStorage.signupType = 'google';
          const { user } = result;
          fullfilUserInfo(user);
          auth.currentUser
            .getIdTokenResult()
            .then((idTokenResult) => {
              // Confirm the user is an Admin.
              console.log(idTokenResult.claims);
              if (
                idTokenResult &&
                idTokenResult.claims &&
                idTokenResult.claims.userSetUp &&
                idTokenResult.claims.userSetUp !== ''
              ) {
                submitSignup();
              }
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (providerType === 'microsoft') {
      const provider = new OAuthProvider('microsoft.com');
      provider.setCustomParameters({ prompt: 'select_account' });
      const auth = getAuth();
      signInWithPopup(auth, provider)
        .then((result) => {
          window.localStorage.signupType = 'microsoft';
          console.log(result);
          const { user } = result;
          fullfilUserInfo(user);
          submitSignup();
        })
        .catch((error) => {
          // Handle error.
          console.log(error);
        });
    } else if (providerType === 'xero') {
      const provider = new OAuthProvider('oidc.xero');
      provider.addScope('openid');
      provider.addScope('profile');
      provider.addScope('email');
      const auth = getAuth();
      signInWithPopup(auth, provider)
        .then((result) => {
          window.localStorage.signupType = 'xero';
          console.log(result);
          const { user } = result;
          fullfilUserInfo(user);
          submitSignup();
        })
        .catch((error) => {
          // Handle Errors here.
          console.log(error);
          if (error && error.code && error.code === 'auth/account-exists-with-different-credential') {
            const { customData } = error;
            const { email } = customData;
            fetchSignInMethodsForEmail(auth, email).then((methods) => {
              console.log(methods);
              const actionCodeSettings = {
                url: `${DOMAIN_URL}/${PATH_AUTH.link}/ms`,
                handleCodeInApp: true
              };
              const auth = getAuth();
              sendSignInLinkToEmail(auth, email, actionCodeSettings)
                .then(() => {
                  window.localStorage.setItem('emailForSignIn', email);
                  window.location.href = PATH_ERROR.linkrequired;
                })
                .catch((error) => {
                  const errorCode = error.code;
                  const errorMessage = error.message;
                  console.log('FirebaseContext sendSignInLinkToEmail error:');
                  console.log(errorCode, errorMessage);
                  // ...
                });
            });
          }
        });
    }
  };

  const registerSuccess = () => {
    const user = {
      displayName: 'Test'
    };
    dispatch({
      type: 'REGISTER',
      payload: {
        isAuthenticated: true,
        user
      }
    });
  };

  const logout = async () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        // Sign-out successful.
        sessionStorage.clear();
        localStorage.clear();
        dispatch({ type: 'LOGOUT' });
      })
      .catch((error) => {
        console.log('FirebaseContext signOut error:');
        console.log(error);
        // An error happened.
      });
  };

  const auth = { ...state.user };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'firebase',
        user: {
          id: auth.uid,
          email: auth.email,
          photoURL: auth.photoURL || profile?.photoURL,
          displayName: auth.displayName || profile?.displayName,
          role: 'user',
          phoneNumber: auth.phoneNumber || profile?.phoneNumber || '',
          country: profile?.country || '',
          address: profile?.address || '',
          state: profile?.state || '',
          city: profile?.city || '',
          zipCode: profile?.zipCode || '',
          about: profile?.about || '',
          isPublic: profile?.isPublic || false
        },
        login,
        register,
        registerSuccess,
        logout
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
