import { createSlice } from '@reduxjs/toolkit';
import { loadDashboardReports } from '../../utils/CradleClient';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  dashboardUrl: '',
  dashboardReportURL: ''
};

const slice = createSlice({
  name: 'report',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET BOARD
    getDashboardSuccess(state, action) {
      state.isLoading = false;
      const url = action.payload.split('?')[0];
      state.dashboardUrl = url;
    },

    getDashboardURLSuccess(state, action) {
      state.isLoading = false;
      state.dashboardReportURL = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

export function getDashboardReportURL() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await loadDashboardReports();
      dispatch(slice.actions.getDashboardURLSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getDashboardKan() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await loadDashboardReports();
      const segments = new URL(response).pathname.split('/');
      const dashboardToken = segments.pop() || segments.pop(); // Handle potential trailing slash
      console.log(dashboardToken);
      const url = `https://report.dev.cradle.io/api/embed/dashboard/${dashboardToken}/dashcard/36/card/26`;
      // const responseInboundCalls = fetch(url);
      // The load balancer in GCP needs to have a Custom Response Header in place to allow us to use this reponse: https://cloud.google.com/load-balancing/docs/https/custom-headers
      const responseInboundCalls = await fetch(url, {
        headers: {
          Origin: 'http://localhost:3000'
        }
      });
      // const responseInboundCalls = await fetch(url, { mode: 'no-cors' });

      const json = JSON.parse(responseInboundCalls);
      console.log(json);
      // const data = await responseInboundCalls.json();
      // https://report.dev.cradle.io/api/embed/dashboard/
      // dispatch(slice.actions.getDashboardSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
